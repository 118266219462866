import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageApiService {


  constructor(private sharedService: SharedService,
    private http: HttpClient) { }

  getManageAPIList(type: any){
    return this.http
    .get(`${environment.baseUrl}/gstin/manage/api/list?type=${type}`)
    .toPromise();
  }

  manageAPIAccess(data: any){
    return this.http
    .post(`${environment.baseUrl}/gstin/manage/api/access`,data)
    .toPromise();
  }
  getGSPERPList(){
     return this.http
    .get(`${environment.baseUrl}/gsp/erp/list`)
    .toPromise();

  }
  getDirectList(){
    return this.http
    .get(`${environment.baseUrl}/gstin/manage/api/list?type=DIRECT`)
  }

  getInterfaceRegister(){
    return this.http
    .get(`${environment.baseUrl}/gstin/api/interface/register`)
    .toPromise();
  }
  
}
